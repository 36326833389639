import React, {FC, lazy, Suspense} from "react";
import {CssBaseline, MuiThemeProvider} from "@material-ui/core";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import theme from "./theme";
import GlobalStyles from "./GlobalStyles";
import * as serviceWorker from "./serviceWorker";
import Pace from "./components/Pace";
import {MDXProvider} from '@mdx-js/tag';
import {components} from "./components/Content";

const LoggedOutComponent = lazy(() => import("./pages/Main"));

const App: FC = () => (
    <BrowserRouter>
        <MuiThemeProvider theme={theme}>
            <MDXProvider components={components}>
                <>
                    <CssBaseline />
                    <GlobalStyles />
                    <Pace color={theme.palette.primary.light} />
                    <Suspense fallback={<div>LOADING....</div>}>
                        <Switch>
                            <Route>
                                <LoggedOutComponent />
                            </Route>
                        </Switch>
                    </Suspense>
                </>
            </MDXProvider>
        </MuiThemeProvider>
    </BrowserRouter>
);

serviceWorker.register();

export default App;