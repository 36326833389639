import React, {FC, lazy, ReactElement} from "react";
import {importMDX} from "mdx.macro";
import classNames from "classnames";
import {withStyles, WithStyles} from "@material-ui/styles";


import Wrapper, {Props as WrapperProps} from './Wrapper';

const MDXImpressum = lazy(() => importMDX('../../content/impressum.md'));
const MDXDatenschutz = lazy(() => importMDX('../../content/datenschutz.md'));


const styles = {
    root: {}
}


const M: FC<WithStyles<typeof styles>> = ({classes, children}) => (
    <div className={classNames("markdown", classes.root)}>{children}</div>);

const MarkdownWrapper = withStyles(styles, {withTheme: true})(M);


export const Impressum: FC = () => <MarkdownWrapper><MDXImpressum /></MarkdownWrapper>;
export const Datenschutz: FC = () => <MarkdownWrapper><MDXDatenschutz /></MarkdownWrapper>;


interface Props {
    children: ReactElement<WrapperProps>[];
}

export const components = {
    wrapper: ({children}: Props) => children.map(
        ({props: {components, children, name}}, key) => (
            <Wrapper key={key} name={name} components={components} children={children} />
        )
    ),
};

