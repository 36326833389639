import React, {FC, memo, ReactElement} from "react";


const wrapperComponents: WrapperComponents = {
    h1: memo(({children, ...props}: any) => <h1 {...props}>{children}</h1>),
    h2: memo(({children, ...props}: any) => <h2 {...props}>{children}</h2>),
    h3: memo(({children, ...props}: any) => <h3 {...props}>{children}</h3>),
    h4: memo(({children, ...props}: any) => <h4 {...props}>{children}</h4>),
    h5: memo(({children, ...props}: any) => <h5 {...props}>{children}</h5>),
    h6: memo(({children, ...props}: any) => <h6 {...props}>{children}</h6>),
    p: memo(({children, ...props}: any) => <p {...props}>{children}</p>),
    ul: memo((props: any) => <ul {...props} />),
    ol: memo((props: any) => <ol {...props} />),
    li: memo((props: any) => <li {...props} />),
    a: memo(({children, ...props}) => <a  {...props}>{children}</a>),
};

export type MarkdownClassKey =
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'ul'
    | 'ol'
    | 'li'
    | 'p'
    | 'a'


type WrapperComponents = {
    [key in MarkdownClassKey]: (props: {
        children: ReactElement<Props>[];
    }) => any;
};

export interface Props {
    children: any;
    components?: any;
    name: MarkdownClassKey;
}

const Wrapper: FC<Props> = ({name, components, children}) => {
    const Component = wrapperComponents[name] || name;


    // if (children instanceof Array) {
    //     console.log('wrapper', name, children, typeof children);
    //
    //     return <>
    //         {children.map((child, idx) => {
    //             console.log('wrapper-' + idx, child, typeof child, child!.constructor.name);
    //
    //             if (typeof child === 'string') {
    //                 return child;
    //             }
    //
    //             // @ts-ignore
    //             const {name, children, components}: Props = child!.props;
    //
    //             return <Wrapper key={idx} name={name} components={components} children={children} />
    //         })}
    //     </>
    // }


    return (<Component {...{name, children}} />);
}


export default Wrapper;